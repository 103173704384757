import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { LogoImage } from '../../svg/LogoImage';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { CompanyInformation } from './CompanyInformation';
// import { ImportantLinks } from './ImportanLinks';

const Footer = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          extension: { eq: "webp" }
          name: { eq: "bg-footer1" }
        }
      ) {
        edges {
          node {
            id
            name
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const image = getImage(data.allFile.edges[0].node.childImageSharp.gatsbyImageData);

  return (
    <footer
      id="site-footer"
      className="mt-auto site-footer"
      style={{
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: '30px',
        paddingTop: '30px',
      }}
    >
      {image && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            backgroundColor: '#211e3b',
          }}
        >
          <GatsbyImage
            image={image}
            alt="Background"
            imgStyle={{ objectFit: 'cover' }}
            loading="lazy"
          />
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="text-center col-md-12">
            <Link to="/">
              <LogoImage width="240px" height="46px" />
            </Link>
          </div>
        </div>
        <div className="space-60"></div>
        <div className="row justify-content-center">
          <div className="col-md-4 col-sm-6">
            <div className="contact-info box-style2 ft-contact-info">
              <div className="box-icon">
                <i className="flaticon-world-globe"></i>
              </div>
              <p>{intl.formatMessage({ id: 'company_data.address' })}</p>
              <h6>Our Address</h6>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="contact-info box-style2 ft-contact-info">
              <div className="box-icon">
                <i className="flaticon-envelope"></i>
              </div>
              <a href={`mailto:${intl.formatMessage({ id: 'company_data.email' })}`}>
                {intl.formatMessage({ id: 'company_data.email' })}
              </a>
              <h6>Our Mailbox</h6>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="contact-info box-style2 ft-contact-info">
              <div className="box-icon">
                <i className="flaticon-phone-1"></i>
              </div>
              <a href={`tel:${intl.formatMessage({ id: 'company_data.phone' })}`}>
                {intl.formatMessage({ id: 'company_data.phone' })}
              </a>
              <h6>Our Phone</h6>
            </div>
          </div>
        </div>
        <div className="space-65"></div>
        <div className="row">
          <div className="text-center col-md-12">
            <div className="footer-menu">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/job-offers">Job Offers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="space-20"></div>
            <p className="copyright-text v2">
              Copyright © 2022 - {new Date().getFullYear()}{' '}
              {intl.formatMessage({ id: 'company_data.name' })} All Rights Reserved.
            </p>
            <div className="space-26"></div>
            <div className="ft-list-icon">
              {/* <a className="twitter" href="twitter.com">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="facebook" href="facebook.com">
                <i className="fab fa-facebook-f"></i>
              </a> */}
              <a className="linkedin" href={intl.formatMessage({ id: 'socials.linkedin' })}>
                <i className="fab fa-linkedin-in"></i>
              </a>
              {/* <a className="instagram" href="instagram.com">
                <i className="fab fa-instagram"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
