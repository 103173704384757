import React, { useState } from 'react';
import { Script } from 'gatsby';
import { StandardLayout } from './templates';

import $ from 'jquery';

const RequiredJavascript = ({ element }) => {
  // const [jquery, setJquery] = useState(false);

  return (
    <>
      <html lang="en" />
      {/* <Script src="/js/jquery.min.js" onLoad={() => setJquery(true)} /> */}
      <div id="page" className="site">
        {element}
      </div>
      {/* {jquery && <Script src="/js/jquery.isotope.min.js" />} */}
      {/* {jquery && <Script src="js/owl.carousel.min.js" />} */}
      {/* {jquery && <Script src="js/easypiechart.min.js" />} */}
      {/* {jquery && <Script src="js/jquery.countdown.min.js" />} */}
      {/* {jquery && <Script src="js/jquery.magnific-popup.min.js" />} */}
      {/* {jquery && <Script src="/js/scripts.js" />} */}
      {/* {jquery && <Script src="/js/header-mobile.js" />} */}
    </>
  );
};

export const wrapRootElement = ({ element }) => <RequiredJavascript element={element} />;

// export const wrapPageElement = ({ element, props }) => {
//   console.log(element, props);
//   const Layout = element.type.layout ?? StandardLayout;
//   return <Layout {...props}>{element}</Layout>;
// };
