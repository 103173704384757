import React from 'react';
import { Link, useIntl } from 'gatsby-plugin-intl';

import { LogoImage } from '../../svg/LogoImage';

import { Menu } from '../Menu';

export const Desktop = () => {
  return (
    <header id="site-header" className="site-header header-style-3 sticky-header">
      <div className="octf-main-header">
        <div className="octf-area-wrap">
          <div className="container octf-mainbar-container">
            <div className="octf-mainbar">
              <div className="octf-mainbar-row octf-row">
                <div className="octf-col logo-col">
                  <div id="site-logo" className="site-logo">
                    <Link to="/">
                      <LogoImage width="240px" height="46px" />
                    </Link>
                  </div>
                </div>
                <Menu />
                <div className="octf-col cta-col text-right cloudtech-contact">
                  {/* <!-- Call To Action --> */}
                  <div className="octf-btn-cta">
                    <div className="octf-header-module cart-btn-hover">
                      <div className="h-cart-btn octf-cta-icons">
                        <Link to="/contact">
                          <i className="flaticon-envelope"></i>
                        </Link>
                      </div>
                    </div>

                    {/* <div className="octf-header-module">
                      <div className="toggle_search octf-cta-icons">
                        <i className="flaticon-search"></i>
                      </div>
                      <div className="h-search-form-field collapse">
                        <div className="h-search-form-inner">
                          <form
                            role="search"
                            method="get"
                            id="hsearch-form"
                            className="search-form"
                          >
                            <label>
                              <span className="screen-reader-text">Search for:</span>
                              <input
                                type="search"
                                className="search-field"
                                placeholder="Search …"
                                value=""
                                name="s"
                              />
                            </label>
                            <button type="submit" className="search-submit">
                              <i className="flaticon-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

/*
    // <div className="container  justify-between items-center mx-auto py-4 px-0 hidden lg:flex lg:flex-row">
    //   <div className="flex items-center">
    //     <Link to="/" className="text-black hover:text-black no-underline hover:no-underline">
    //       <StaticImage
    //         src="../../../images/logo_cloudtech.png"
    //         alt="cloudtech.consulting logo"
    //         placeholder="blurred"
    //         layout="fixed"
    //         width={310}
    //         loading="eager"
    //       />
    //     </Link>
    //   </div>
    //   <div className="flex mt-6 sm:mt-0">
    //     <Menu />
    //   </div>
    //   <div className="md:block">
    //     <Link to={contactUrl}>
    //       <Button className="text-xl">{intl.formatMessage({ id: 'contact_us.label' })}</Button>
    //     </Link>
    //   </div>
    //   <div className="flex mt-1">
    //     <LanguageChanger />
    //   </div>
    // </div>
*/
