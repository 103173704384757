import { onCLS, onFID, onLCP, onTTFB } from 'web-vitals';

export { wrapRootElement } from './gatsby-shared';

import './src/css/bootstrap.min.css';
import './src/css/font-awesome.min.css';
import './src/css/flaticon.css';
// import './src/css/owl.carousel.min.css';
// import './src/css/owl.theme.css';
import './src/css/magnific-popup.css';

import 'prismjs/themes/prism.min.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css';

import './src/css/style.css';

import './src/css/own.css'




import $ from 'jquery';

// Logs CLS as the value changes.

//for now only for development

export const onRouteUpdate = () => {
  onCLS(console.log, { reportAllChanges: true });
  onFID(console.log, { reportAllChanges: true });
  onLCP(console.log, { reportAllChanges: true });
  onTTFB(console.log, { reportAllChanges: true });

  //mobile menu function
  $(function () {
    $('#mmenu_toggle').on('click', function () {
      $(this).toggleClass('active');

      if ($(this).hasClass('active')) {
        $('.mobile_nav').stop(true, true).slideDown();
      } else {
        $('.mobile_nav').stop(true, true).slideUp();
      }
    });

    $('#mmenu_toggle').on('click', function () {
      $(this).parents('.header_mobile').toggleClass('active');
    });

    $('.mobile_mainmenu > li span.arrow').on('click', function () {
      $(this).parent().find('> ul').stop(true, true).slideToggle();
      $(this).toggleClass('active');
    });
    console.log('onRouteUpdate');
  });
};


