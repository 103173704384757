import React from 'react';

import MainLayout from './StandardLayout';

export default function ({ children }) {
  return (
    <MainLayout>
      <main className="text-gray-900">{children}</main>
    </MainLayout>
  );
}
