import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import { LogoImage } from '../../svg/LogoImage';
// TODO: fix links to contact
export const Mobile = () => {
  const intl = useIntl();
  const { locale } = intl;

  // const contactUrl = locale === 'pl' ? '/kontakt' : '/contact';
  return (
    <div className="header_mobile">
      <div className="container">
        <div className="mlogo_wrapper clearfix">
          <div className="mobile_logo">
            <Link to="/">
              <LogoImage width="240px" height="46px" color="#444" />
            </Link>
          </div>
          <div className="octf-btn-cta contact-mobile">
            <div className="octf-header-module  cart-btn-hover">
              <div className="h-cart-btn octf-cta-icons">
                <Link to="/contact">
                  <i className="flaticon-envelope"></i>
                </Link>
              </div>
            </div>
          </div>
          <div id="mmenu_toggle">
            <button></button>
          </div>
        </div>
        <div className="mmenu_wrapper">
          <div className="mobile_nav collapse">
            <ul id="menu-main-menu" className="mobile_mainmenu">
              <li className="menu-item-has-children current-menu-item current-menu-ancestor">
                <Link to="/" activeClassName="current-menu-ancestor">
                  Home
                </Link>
              </li>
              <li className="menu-item-has-children">
                <a>Our offer</a>
                <span className="arrow damian">
                  <i className="flaticon-right-arrow"></i>
                </span>
                <ul className="sub-menu">
                  <li>
                    <Link to="/offer/cloud-consulting" activeClassName="current-menu-ancestor">
                      Cloud Consulting
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/offer/digital-transformation"
                      activeClassName="current-menu-ancestor"
                    >
                      Digital Transformation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/offer/application-development"
                      activeClassName="current-menu-ancestor"
                    >
                      Application Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/offer/it-recruitment" activeClassName="current-menu-ancestor">
                      IT Recruitment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/offer/engineering-team-management"
                      activeClassName="current-menu-ancestor"
                    >
                      Engineering Team Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/offer/it-trainings" activeClassName="current-menu-ancestor">
                      IT Trainings
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/job-offers" activeClassName="current-menu-ancestor">
                  Job Offers
                </Link>
              </li>
              <li>
                <Link to="/contact" activeClassName="current-menu-ancestor">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/blog" activeClassName="current-menu-ancestor">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
