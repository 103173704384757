import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

const Header = () => {
  const mobileSize = useMediaQuery('(max-width: 1024px)');
  return (
    <header className="sticky top-0 bg-white shadow z-10">
      {!mobileSize ? <Desktop /> : <Mobile />}
    </header>
  );
};

export default Header;
